import { configureStore, combineReducers } from "@reduxjs/toolkit";
// Imports for Redux Persistance
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

// Import Reducers
import leadReducer from "../features/lead/leadSlice";
import prequalReducer from "../features/prequal/prequalSlice";
import aduValueReducer from "../features/aduvalue/aduValueSlice";

// Create Root Reducer
const rootReducer = combineReducers({
  lead: leadReducer,
  prequal: prequalReducer,
  aduvalue: aduValueReducer,
});

// Redux Persistance Config
const persistConfig = {
  key: "root",
  storage,
};

// Create Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
