// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import prequalService from "./prequalService";

// Create Initial State for PreQual form
const initialState = {
  prequal: [],
  recordId: "",
  formData: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get PreQual from Intake
export const getPreQual = createAsyncThunk("prequal/get", async (_, thunkAPI) => {
  try {
    return await prequalService.getPreQual();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const createPreQual= createAsyncThunk(
  "prequal/create",
  async (request, thunkAPI) => {
    try {
      return await prequalService.createPreQual(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePreQual = createAsyncThunk(
  "prequal/update",
  async (request, thunkAPI) => {
    try {
      return await prequalService.updatePreQual(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    // TODO: I think that these comments are left over from this
    // "Slice" being copied from an authentication tutorial.
    reset: (state) => {
      state.prequal = [];
      state.formData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get PreQual 
      .addCase(getPreQual.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPreQual.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.prequal = action.payload;
      })
      .addCase(getPreQual.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create PreQual 
      .addCase(createPreQual.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPreQual.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recordId = action.payload.id;
        state.formData = action.payload;
      })
      .addCase(createPreQual.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update PreQual
      .addCase(updatePreQual.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePreQual.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formData = action.payload;
      })
      .addCase(updatePreQual.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
