import api from "..";

import hubSpotService from "./hubSpotService";

// Get PreQual
const getPreQual = async () => {
  // Make Request, Include Response in Variable
  const response = await api.get("/prequal");

  return response.data;
};

const createPreQual = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.post("/prequal", request);

  return response.data;
};

const updatePreQual = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.put("/prequal", request);

  if ( request.email !== undefined ) {  
    hubSpotService.upsertHubSpot({formData: {
      ...request,
      Created: new Date().toISOString()
    }});
  }

  return response.data;
};

const deletePreQual = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.delete("/prequal", request);

  return response.data;
};

const preQualService = {
  getPreQual,
  createPreQual,
  updatePreQual,
  deletePreQual,
};

export default preQualService;
