import React from "react";

export const ProgressBar = ({ numSteps, currentStep }) => {
  const interval = 100 / numSteps;
  const progress = (interval * (currentStep + 1)).toFixed(2);

  return (
    <>
      <div className="">
        <h4 className="sr-only">Progress</h4>
        <div className="mt-2" aria-hidden="true">
          <div className="overflow-hidden bg-white">
            <div
              className="h-2 bg-red-300 ease-in-out duration-500"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
