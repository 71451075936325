import api from "..";

// Get ADU Value
const getADUValue = async (zip_code) => {
  // Make Request, Include Response in Variable
  const response = await api.get("/aduvalue?zip_code=" + zip_code);

  if ( response.data.length !== 1 ) {
    throw Error(`Unexpected response size: ${response.data.length}, expected 1`)
  }
  return response.data[0];
};

const aduValueService = {
  getADUValue,
};

export default aduValueService;
