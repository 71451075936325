// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import aduValueService from "./aduValueService";

// Create Initial State for ADUValue store
const initialState = {
  aduvalue: {},
  recordId: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get aduValue from value service
export const getADUValue = createAsyncThunk(
  "aduvalue/get",
  async (zip_code, thunkAPI) => {
    try {
        return await aduValueService.getADUValue(zip_code);
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

        return thunkAPI.rejectWithValue(message);
    }
});

// Slice is collection of Reducers for Redux
export const authSlice = createSlice({
  name: "aduvalue",
  initialState,
  extraReducers: (builder) => {
    builder
      // GetADUValue
      .addCase(getADUValue.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getADUValue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.aduvalue = action.payload;
      })
      .addCase(getADUValue.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.aduvalue={};
        state.message = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
