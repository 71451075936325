import React from "react";
import { Outlet } from "react-router-dom";

export const RootLayout = () => {
  return (
    <>
      <div className="min-h-screen justify-center items-center bg-red-100 mx-auto sm:px-6 lg:px-8">
        <Outlet />
      </div>
    </>
  );
};
