import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // RouterProvider,
  // createBrowserRouter,
  // createRoutesFromElements,
} from "react-router-dom";
import { RootLayout } from "./components/templates/RootLayout";
import Intake from "./pages/Intake";
import PreQual from "./pages/PreQual";

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<RootLayout />}>
          <Route path="/" element={<Intake />} />
        </Route>
        <Route element={<RootLayout />}>
          <Route path="/pq" element={<PreQual />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
