import React from "react";

export const HeaderText = ({ text }) => {
  return (
    <>
      <div className="px-4 pt-4 sm:pt-6 sm:px-6">
        <div>
          <h1 className="text-center text-3xl leading-normal md:text-5xl md:leading-normal font-bold tracking-wide text-neutral-900">
            {text}
          </h1>
        </div>
      </div>
    </>
  );
};
