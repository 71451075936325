import React from "react";

export const FormLayout = ({
  headerComponent,
  mainComponent,
  footerComponent,
  handleSubmit,
  onSubmit,
  preHeaderMessage
}) => {
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
      { preHeaderMessage !== undefined && (
        <div className="pt-8 sm:px-6">
          <div className="flex flex-1 justify-center">
            <div className="max-w-2xl text-2xl border-rose-300 border-4 p-4 rounded-lg bg-rose-100">{preHeaderMessage}</div>
          </div>
        </div>
      )}
      {/* Header */}
      <div className="px-4 sm:px-6">
        <div className="flex flex-1 justify-center">
          <div className="max-w-4xl">{headerComponent}</div>
        </div>
      </div>
      {/* Body */}

      <div className="px-4 sm:py-5 sm:px-6">{mainComponent}</div>

      {/* Footer */}
      <div className="px-4 sm:px-6">{footerComponent}</div>
      </form>
    </>
  );
};
