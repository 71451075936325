import React from "react";
import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const RadioCard = ({ key, item, checked, active }) => {
  return (
    <>
      <RadioGroup.Option
        value={item}
        className={({ checked, active }) =>
          classNames(
            checked ? "border-transparent" : "border-gray-300",
            active ? "border-red-400 ring-2 ring-red-400" : "",
            "h-32 sm:h-34 md:h-40 aspect-square relative cursor-pointer rounded-lg border bg-white flex items-center shadow-sm focus:outline-none"
          )
        }
      >
        {({ checked, active }) => (
          <>
            <div className="flex flex-1 p-1">
              <span className="flex flex-col justify-between mx-auto">
                <RadioGroup.Label
                  as="div"
                  className="mx-auto flex h-14 w-14 md:h-20 md:w-20 items-center justify-center rounded-full bg-red-50"
                >
                  {item.icon}
                </RadioGroup.Label>
                <RadioGroup.Description
                  as="div"
                  className="mt-2 md:mt-4 flex items-center text-sm md:text-xl font-medium text-gray-800 mx-auto text-center"
                >
                  <span>{item.title}</span>
                </RadioGroup.Description>
              </span>
              <span
                className={classNames(
                  active ? "border" : "border-2",
                  checked ? "border-red-400" : "border-transparent",
                  "pointer-events-none absolute -inset-px rounded-lg"
                )}
                aria-hidden="true"
              />
            </div>
          </>
        )}
      </RadioGroup.Option>
    </>
  );
};
