import React  from "react";
import { FormLayout, HeaderText, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePreQual } from "../../features/prequal/prequalSlice";

export const PreQualStep7 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { formData: leadData } = useSelector((state) => state.lead);
  const { recordId, formData } = useSelector((state) => state.prequal);

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({});

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: leadData.email,
      total_debt_payments: Number(data.primary_mortgage_payment)
                           + Number(data.car_payment)
                           + Number(data.credit_card_payment)
                           + Number(data.student_loan_payment)
                           + Number(data.hel_heloc_payment)
                           + Number(data.child_support_alimony_payment),
      primary_mortgage_payment: Number(data.primary_mortgage_payment),
      car_payment: Number(data.car_payment),
      credit_card_payment: Number(data.credit_card_payment),
      student_loan_payment: Number(data.student_loan_payment),
      hel_heloc_payment: Number(data.hel_heloc_payment),
      child_support_alimony_payment: Number(data.child_support_alimony_payment),
    };

    dispatch(updatePreQual(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"What monthly payments are you making?"} />
          </>
        }
        mainComponent={
          <>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="primary_mortgage_payment" className="text-m font-bold">
                  Primary Mortgage Payment
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="primary_mortgage_payment"
                    defaultValue={formData.primary_mortgage_payment}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="3,000"
                    {...register("primary_mortgage_payment", { required: false })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">Your full monthly mortgage payment including insurance and property taxes.</div>
              </div>
            </div>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="car_payment" className="text-m font-bold">
                  Car Payment
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="car_payment"
                    defaultValue={formData.car_payment}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="0"
                    {...register("car_payment", { required: false })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">All your monthly lease or loan payments combined.</div>
              </div>
            </div>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="credit_card_payment" className="text-m font-bold">
                  Minimum Credit Card Payments
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="credit_card_payment"
                    defaultValue={formData.credit_card_payment}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="0"
                    {...register("credit_card_payment", { required: false })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">Combined minimum payments for your credit card debt. Leave blank if you pay off your balances every month.</div>
              </div>
            </div>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="student_loan_payment" className="text-m font-bold">
                  Student Loan Payment
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="student_loan_payment"
                    defaultValue={formData.student_loan_payment}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="0"
                    {...register("student_loan_payment", { required: false })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">Monthly student loan payment, if any.</div>
              </div>
            </div>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="hel_heloc_payment" className="text-m font-bold">
                  Home Equity Loan or HELOC Payment
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="hel_heloc_payment"
                    defaultValue={formData.hel_heloc_payment}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="0"
                    {...register("hel_heloc_payment", { required: false })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">Your second mortgage payment, if any.</div>
              </div>
            </div>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="child_support_alimony_payment" className="text-m font-bold">
                  Child Support/Alimony Payments
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="child_support_alimony_payment"
                    defaultValue={formData.child_support_alimony_payment}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="0"
                    {...register("child_support_alimony_payment", { required: false })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
                <div className="text-sm mt-2">Your monthly child support or alimony payments, if any.</div>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
