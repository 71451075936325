import React, { useState, useEffect, useRef } from "react";
import hubSpotService from "../features/prequal/hubSpotService";
import { useDispatch } from "react-redux";
import { createLead } from "../features/lead/leadSlice";

import {
  ContainerLayout,
  Header,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  // I consolidated Step9 and Step10 into Step8
  Step11,
  Step12,
  Step13,
  Completed,
  ProgressBar,
  Footer,
} from "../components";

const Intake = () => {
  const searchParamsRef = useRef(new URLSearchParams(window.location.search));

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const formSteps = [
    <Step1 page={page} setPage={setPage} />,
    <Step2 page={page} setPage={setPage} />,
    <Step3 page={page} setPage={setPage} />,
    <Step4 page={page} setPage={setPage} />,
    <Step5 page={page} setPage={setPage} />,
    <Step6 page={page} setPage={setPage} />,
    <Step7 page={page} setPage={setPage} />,
    <Step8 page={page} setPage={setPage} />,
    <Step9 page={page} setPage={setPage} />,
    <Step11 page={page} setPage={setPage} />,
    <Step12 page={page} setPage={setPage} />,
    <Step13 page={page} setPage={setPage} />,
    <Completed page={page} setPage={setPage} />,
  ];
  
  const [inUseFormSteps, setInUseFormSteps] = useState([ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const email = searchParamsRef.current.get("invitee_email");

    const fetchHubSpotData = async () => {
      let contactInfo = await hubSpotService.lookupHubSpot(email);

      if ( contactInfo.has_address ) {
        setInUseFormSteps([ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]);
        dispatch(createLead({ email: email})).then(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }

    if ( email === null ) {
      setLoading(false);
    } else {
      fetchHubSpotData();
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header numSteps={inUseFormSteps.length} currentStep={page + 1} />
      <ProgressBar numSteps={inUseFormSteps.length} currentStep={page} />
      <ContainerLayout>{formSteps[inUseFormSteps[page]]}</ContainerLayout>
      <Footer page={page} setPage={setPage} />
    </>
  );
};

export default Intake;
