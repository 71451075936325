import React from "react";
import { FormLayout } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PreQualCalculator } from "../../features/prequal/prequalCalculator";
import { updatePreQual } from "../../features/prequal/prequalSlice";

export const PreQualResults = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.prequal);

  const { handleSubmit, } = useForm({});

  const onSubmit = (data) => {

    var dbUpdate = {
      id: recordId,
      email: data.email,
      how_you_heard: data.how_you_heard,
      phone_number: data.phone_number,
      sms_opt_in: data.sms_opt_in,
      phone_opt_in: data.phone_opt_in,
      email_opt_in: data.email_opt_in,
    };

    const calculator = new PreQualCalculator({
      ...formData,
    });

    dbUpdate.revival_loan_eligible = calculator.RevivalLoanMaxLoanAmount >= 25000;
    dbUpdate.revival_loan_max_amount = calculator.RevivalLoanMaxLoanAmount < 25000 ? 0 : calculator.RevivalLoanMaxLoanAmount;
    dbUpdate.heloc_eligible = calculator.HELOCMaxLoanAmount >= 25000;
    dbUpdate.heloc_max_amount = calculator.HELOCMaxLoanAmount < 25000 ? 0 : calculator.HELOCMaxLoanAmount;

    dispatch(updatePreQual(dbUpdate)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>{/* <HeaderText text={"Contact Information"} /> */}</>
        }
        mainComponent={
          <>
            <div className="overflow-hidden bg-transparent py-8 sm:py-12">
              <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                  {/* Left Side */}
                  <div className="hidden lg:flex sm:px-6 lg:px-0">
                    <div className="relative isolate overflow-hidden bg-red-300 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                      <div
                        className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-red-100 opacity-20 ring-1 ring-inset ring-white"
                        aria-hidden="true"
                      />
                      <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                        <img
                          src="https://uploads-ssl.webflow.com/63b71a6727a161f30cbd6e3e/63c0947e340013320cc18297_AdobeStock_558273730_Optimized.jpg"
                          alt="Product screenshot"
                          width={2432}
                          height={1442}
                          className="-mb-12 w-[57rem] max-w-none rounded-tl-xl"
                        />
                      </div>
                      <div
                        className="pointer-events-none absolute inset-0 sm:rounded-3xl"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  {/* Right Side */}
                  <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
                    <div className="mb-8">
                      <h1 className="text-2xl text-left font-bold tracking-wide leading-tight text-neutral-900">
                        Thank you for your responses!
                      </h1>

                      <p className="mt-1 text-m leading-6 text-gray-600">
                      Our ADU expert will recommend financing options on the call.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footerComponent={<></>}
      />
    </>
  );
};
