import React, { useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { NoSymbolIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { FormLayout, HeaderText, Button } from "../../components";
import { RadioCard } from "../atoms/RadioCard";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";

const options = [
  {
    id: 1,
    title: "Yes",
    description: "I own this property",
    icon: (
      <CheckCircleIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: true,
  },
  {
    id: 2,
    title: "No",
    description: "I do not currently own this property",
    icon: (
      <NoSymbolIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Step2 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.lead);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (formData.owns_property !== undefined) {
      const option = options.find(
        (option) => option.value === formData.owns_property
      );
      setSelected(option);
    }
  }, [ formData.owns_property ]);

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
    // setPage(page + 1);
  };

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: formData.email,
      owns_property: selected.value,
    };

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        preHeaderMessage={formData.email !== undefined ? "Thank you for scheduling your consultation. Please answer a few questions to help us better understand your project." : undefined}
        headerComponent={
          <>
            <HeaderText text={"Do you currently own the property where you intend to build?"} />
          </>
        }
        mainComponent={
          <>
            <RadioGroup value={selected} onChange={onChange}>
              <RadioGroup.Label className="sr-only">
                Select Option
              </RadioGroup.Label>
              <div className="flex justify-center p-4">
                <div className="grid grid-cols-2 gap-x-6">
                  {options.map((option) => (
                    <div key={option.id}>
                      <RadioCard item={option} />
                    </div>
                  ))}
                </div>
              </div>
            </RadioGroup>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
