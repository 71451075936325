import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { FormLayout, Button, HeaderText, RadioCard } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePreQual} from "../../features/prequal/prequalSlice";
import { NoSymbolIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = [
  {
    id: 1,
    title: "Yes",
    description: "Yes",
    icon: (
      <CheckCircleIcon
        className={classNames("h-14 w-14 text-red-400")}k
        aria-hidden="true"
      />
    ),
    value: true,
  },
  {
    id: 2,
    title: "No",
    description: "No",
    icon: (
      <NoSymbolIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: false,
  },
];

export const PreQualStep6 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { formData: leadData } = useSelector((state) => state.lead);
  const { recordId, formData } = useSelector((state) => state.prequal);
  const [selected, setSelected] = useState(options.find((obj) => obj.value === formData.is_self_employed));

  if ( selected === undefined ) {
    setSelected(false);
  }

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
    // setPage(page + 1);
  };

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: leadData.email,
      is_self_employed: selected.value,
    };

    dispatch(updatePreQual(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"Are you self-employed or do you derive a signficant portion of your income from a business you own?"} />
          </>
        }
        mainComponent={
          <>
            <div>
              <RadioGroup value={selected} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                  Select Option
                </RadioGroup.Label>
                <div className="flex justify-center p-4">
                  <div className="mt-4 grid grid-cols-2 gap-x-6">
                    {options.map((option) => (
                      <RadioCard key={option.id} item={option} />
                    ))}
                  </div>
                </div>
              </RadioGroup>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
