import React from "react";
import { FormLayout, HeaderText, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";

export const Step12 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.lead);

  const {
    handleSubmit,
    register,
  } = useForm({
    defaultValues: formData,
  });

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: formData.email,
      additional_information: data.additional_information,
    };

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText
              text={"What else do you want to tell your ADU consultant?"}
            />
          </>
        }
        mainComponent={
          <>
            <div className="pt-8 p-4 flex flex-1 justify-center">
              <div className="max-w-2xl flex flex-1 flex-col">
                <div>
                  <label htmlFor="comment" className="sr-only">
                    Add additional information
                  </label>
                  <div>
                    <textarea
                      rows={4}
                      name="comment"
                      id="comment"
                      className="w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      defaultValue={""}
                      autoFocus="autofocus"
                      {...register("additional_information")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
