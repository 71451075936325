export class PreQualCalculator {
  constructor(prequal) {
    this.estimated_loan = prequal.estimated_loan;
    this.home_value     = prequal.home_value;
    this.adu_size       = prequal.adu_size;
    this.estimated_remaining_mortgage = prequal.estimated_remaining_mortgage;
    this.combined_income = prequal.combined_income;
    this.project_reason  = prequal.project_reason;
    this.estimated_credit_score = prequal.estimated_credit_score;
    this.total_debt_payments = prequal.total_debt_payments;
    this.monthly_adu_income = prequal.monthly_adu_income;
  }

  get PercentValueIncreasePostADU() {
    switch ( this.adu_size) {
      case "Studio":
        return 0.18;
      case "1 Bedroom":
        return 0.21;
      case "2 Bedrooms":
        return 0.35;
      case "3+ Bedrooms":
        return 0.35;
      default:
        // This should not be reachable.
        return 0;
    }
  }

  get PostADUTotalDebt() {
    return this.estimated_remaining_mortgage + this.estimated_loan;
  }

  get PostADUPropertyValuation() {
    return this.home_value + ( this.home_value * this.PercentValueIncreasePostADU );
  }

  get PreADULoanToValue() {
    return this.estimated_remaining_mortgage / this.home_value;
  }

  get ADULoanAPR() {
    return 0.075
  }

  get ADUMonthlyLoanPayment() {
    return ( this.estimated_loan * this.ADULoanAPR ) / 12
  }

  get PostADUMonthlyDebtPayments() {
    return this.total_debt_payments + this.ADUMonthlyLoanPayment
  }
  
  get PreADUDebtToIncome() {
    return this.PostADUMonthlyDebtPayments / (this.combined_income / 12)
  }

  get PostADUDebtToIncome() {
    return this.PostADUMonthlyDebtPayments / ((this.combined_income / 12) + (this.monthly_adu_income * 0.75))
  }

  get MaxDebtToIncome() {
    return 0.43
  }

  get HELOCMaxPreADULoanToValue() {
    return 0.8
  }

  get HELOCMaxLoanAmountViaCurrentLoanToValue() {
    return Math.round((this.home_value * this.HELOCMaxPreADULoanToValue) - this.estimated_remaining_mortgage)
  }

  get HELOCMaxLoanAmountViaDebtToIncome() {
    return Math.max(
      Math.round((((this.MaxDebtToIncome * (this.combined_income / 12)) - this.total_debt_payments) * 12 ) / this.ADULoanAPR),
      0
    )
  }

  get HELOCLoanSizeLimit() {
    return 300000;
  }

  get HELOCMaxLoanAmount() {
    return Math.max(
      Math.min(this.HELOCMaxLoanAmountViaDebtToIncome, this.HELOCMaxLoanAmountViaCurrentLoanToValue, this.HELOCLoanSizeLimit),
      0
    );
  }

  get PostADUMonthlyIncome() {
    // I have a question in to Anthony about why we're reducing monthly_adu_income by 25%
    return (this.combined_income / 12) + (this.monthly_adu_income * 0.75);
  }

  get HELOCPostADULoanToValue() {
    return this.PostADUTotalDebt / this.home_value;
  }

  get HELOCFullLoanQualifiedViaDebtToIncome() {
    if ( this.estimated_loan < this.HELOCLoanSizeLimit && this.PreADUDebtToIncome < this.MaxDebtToIncome ) {
      return true;
    } else {
      return false;
    }
  }

  get HELOCFullLoanQualifiedViaLoanToValue() {
    if ( this.estimated_loan < this.HELOCLoanSizeLimit && this.HELOCPostADULoanToValue < this.HELOCMaxPreADULoanToValue ) {
      return true
    } else {
      return false
    }
  }

  get HELOCFullLoanQualified() {
    return this.HELOCFullLoanQualifiedViaDebtToIncome && this.HELOCFullLoanQualifiedViaLoanToValue
  }

  // Revival Loan Specific Calculations
  get RevivalLoanSizeLimit() {
    return 300000;
  }

  get RevivalLoanMaxCurrentLoanToValue() {
    return 1
  }

  get RevivalLoanMaxPostADULoanToValue() {
    return .8
  }

  get RevivalLoanMaxLoanAmountViaCurrentLoanToValue() {
    return this.RevivalLoanMaxCurrentLoanToValue * (this.home_value - this.estimated_remaining_mortgage);
  }

  get RevivalLoanMaxLoanAmountViaFutureLoanToValue() {
    return this.RevivalLoanMaxPostADULoanToValue * this.PostADUPropertyValuation - this.estimated_remaining_mortgage;
  }

  get RevivalLoanPostADULoanToValue() {
    return this.PostADUTotalDebt / this.PostADUPropertyValuation;
  }

  get RevivalLoanMaxLoanAmountViaDebtToIncome() {
    return Math.round(
      Math.max(
        (((this.MaxDebtToIncome * this.PostADUMonthlyIncome) - this.total_debt_payments)* 12) / this.ADULoanAPR,
        0
      )
    );
  }

  get RevivalLoanMaxLoanAmount() {
    return Math.min(
      this.RevivalLoanMaxLoanAmountViaDebtToIncome,
      this.RevivalLoanMaxLoanAmountViaCurrentLoanToValue,
      this.RevivalLoanMaxLoanAmountViaFutureLoanToValue,
      this.RevivalLoanSizeLimit
    )
  } 

  get RevivalLoanFullLoanQualifiedViaDebtToIncome() {
    if ( this.estimated_loan < this.RevivalLoanSizeLimit && this.PostADUDebtToIncome < this.MaxDebtToIncome ) {
      return true;
    } else {
      return false;
    }
  }

  get RevivalLoanFullLoanQualifiedViaLoanToValue() {
    if ( (this.estimated_loan < this.RevivalLoanSizeLimit) && 
         (((this.RevivalLoanPostADULoanToValue > this.RevivalLoanMaxPostADULoanToValue)) || (this.PreADULoanToValue > this.RevivalLoanMaxCurrentLoanToValue)) )
    {
      return false
    } else { 
      return true
    }
  }

  get RevivalLoanFullLoanQualified() {
    return this.RevivalLoanFullLoanQualifiedViaDebtToIncome && this.RevivalLoanFullLoanQualifiedViaLoanToValue
  }

};
