import React, { forwardRef } from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Button = forwardRef(
  (
    {
      size = "regular",
      variant = "primary",
      pill = false,
      children,
      className,
      leftIcon,
      rightIcon,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <button
          ref={ref}
          disabled={disabled}
          className={classNames(
            "inline-flex items-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-offset-2",
            size === "xsmall" && "px-2.5 py-1.5 text-xs",
            size === "small" && "px-3 py-2 text-sm leading-4",
            size === "regular" && "px-4 py-2 text-sm",
            size === "large" && "px-4 py-2 text-base",
            size === "xlarge" && "px-6 py-3 text-base",
            variant === "primary" &&
              "border-transparent bg-red-400 text-white hover:bg-red-600 disabled:bg-gray-200 disabled:text-gray-400 disabled:cursor-not-allowed",
            variant === "secondary" &&
              "border-transparent bg-white text-red-500 hover:bg-red-50",
            variant === "white" &&
              "border-gray-300 bg-white text-gray-700 hover:bg-gray-50",
            pill ? "rounded-full" : "rounded-md",
            className
          )}
          {...props}
        >
          {leftIcon && (
            <span
              className={classNames(
                "",
                size === "xsmall" && "hidden",
                size === "small" && "-ml-0.5 mr-2 h-4 w-4",
                size === "regular" && "-ml-1 mr-2 h-5 w-5",
                size === "large" && "-ml-1 mr-3 h-5 w-5",
                size === "xlarge" && "-ml-1 mr-3 h-5 w-5"
              )}
            >
              {leftIcon}
            </span>
          )}
          {children}
          {rightIcon && (
            <span
              className={classNames(
                "",
                size === "xsmall" && "hidden",
                size === "small" && "ml-2 -mr-0.5 h-4 w-4",
                size === "regular" && "ml-2 -mr-1 h-5 w-5",
                size === "large" && "ml-3 -mr-1 h-5 w-5",
                size === "xlarge" && "ml-3 -mr-1 h-5 w-5"
              )}
            >
              {rightIcon}
            </span>
          )}
        </button>
      </>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["button", "submit", "dropdown"]),
  className: PropTypes.string,
  pill: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "white",
    "rounded",
    "text",
  ]),
  size: PropTypes.oneOf(["xsmall", "small", "regular", "large", "xlarge"]),
};
