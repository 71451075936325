import { HeaderText } from "../atoms/HeaderText";
import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createLead } from "../../features/lead/leadSlice";
import { useSearchParams } from "react-router-dom";
import hubSpotService from "../../features/prequal/hubSpotService";

export function Step1({ page, setPage }) {
  const dispatch = useDispatch();

  let [searchParams] = useSearchParams();

  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid },
  } = useForm({});

  const onSubmit = (data) => {
    const request = {
      email: searchParams.get("invitee_email"),
      address_line1: data.address_line1,
      address_line2: data.address_line2,
      city: data.city,
      state: data.state,
      zip_code: data.postal_code,
      status: "In progress",
    };

    dispatch(createLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        // Fire off a hubspot update
        hubSpotService.upsertHubSpot({
          form: "intake",
          formData: {
            ...request,
          },

        })
        .then( result => {
          console.log(result)
        },
        e => {
          console.error(e)
        })
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"Where do you want to build your ADU?"} />
          </>
        }
        mainComponent={
          <>
            <div className="py-4">
              <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 sm:max-w-3xl mx-auto">
                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Street address
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="address_line1"
                      id="address-line1"
                      autoComplete="address-line1"
                      autoFocus="autofocus"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      {...register("address_line1", { required: true })}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <label
                    htmlFor="street-address"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Unit, Apt., Suite, etc.
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="address_line2"
                      id="address-line2"
                      autoComplete="address-line2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      {...register("address_line2")}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    City
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      {...register("city", { required: true })}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    State / Province
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      {...register("state", { required: true })}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    ZIP / Postal code
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="postal_code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                      {...register("postal_code", { required: true })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || !isDirty}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
