import React, { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { FormLayout, Button, HeaderText, RadioCard } from "../../components";
import {
  BanknotesIcon,
  HomeModernIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";
import { ReactComponent as UmbrellaIcon } from "../../assets/beach-umbrella.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = [
  {
    id: 1,
    title: "Extra Living Space",
    description: "Extra Living Space",
    icon: (
      <HomeModernIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "Extra Living Space",
  },
  {
    id: 2,
    title: "Housing for Family or Friend",
    description: "Housing for Family or Friend",
    icon: (
      <UsersIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "Housing for Family or Friend",
  },
  {
    id: 3,
    title: "Long-term rental",
    description: "Long-term rental",
    icon: (
      <BanknotesIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "Long-term rental",
  },
  {
    id: 4,
    title: "Airbnb or short-term rental",
    description: "Airbnb or short-term rental",
    icon: (
      <UmbrellaIcon
        className={classNames("h-14 w-14 text-red-400")}
        aria-hidden="true"
      />
    ),
    value: "Airbnb or short-term rental",
  },
];

export const Step6 = ({ page, setPage }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);

  const { recordId, formData } = useSelector((state) => state.lead);

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
    // setPage(page + 1);
  };

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: formData.email,
      project_reason: selected.value,
    };

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  useEffect(() => {
    if (formData.project_reason !== undefined) {
      const option = options.find(
        (option) => option.value === formData.project_reason
      );
      setSelected(option);
    }
  }, [ formData.project_reason ]);

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"Why are you interested in building an ADU?"} />
          </>
        }
        mainComponent={
          <>
            <div>
              <RadioGroup value={selected} onChange={onChange}>
                <RadioGroup.Label className="sr-only">
                  Select Option
                </RadioGroup.Label>
                <div className="flex justify-center p-4">
                  <div className="mt-4 grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4 sm:gap-x-4">
                    {options.map((option) => (
                      <RadioCard key={option.id} item={option} />
                    ))}
                  </div>
                </div>
              </RadioGroup>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"button"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
