import React from "react";
import { FormLayout, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";
import hubSpotService from "../../features/prequal/hubSpotService";

export const Step13 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.lead);

  const {
    handleSubmit,
    register,
    formState: { isDirty, isValid },
  } = useForm({});

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.phone_number,
    };

    var hubspotUpdate = {
      form: 'intake',
      formData: {
        ...formData,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
      }
    };

    // Fire off a hubspot update
    hubSpotService.upsertHubSpot(hubspotUpdate)
    .then( result => {
      console.log(result)
    },
    e => {
      console.error(e)
    })

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>{/* <HeaderText text={"Contact Information"} /> */}</>
        }
        mainComponent={
          <>
            <div className="overflow-hidden bg-transparent py-8 sm:py-12">
              <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                  {/* Left Side */}
                  <div className="hidden lg:flex sm:px-6 lg:px-0">
                    <div className="relative isolate overflow-hidden bg-red-300 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                      <div
                        className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-red-100 opacity-20 ring-1 ring-inset ring-white"
                        aria-hidden="true"
                      />
                      <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                        <img
                          src="https://uploads-ssl.webflow.com/63b71a6727a161f30cbd6e3e/63c0947e340013320cc18297_AdobeStock_558273730_Optimized.jpg"
                          alt="Product screenshot"
                          width={2432}
                          height={1442}
                          className="-mb-12 w-[57rem] max-w-none rounded-tl-xl"
                        />
                      </div>
                      <div
                        className="pointer-events-none absolute inset-0 sm:rounded-3xl"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  {/* Right Side */}
                  <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
                    <div className="mb-8">
                      <h1 className="text-4xl sm:text-5xl text-left font-bold tracking-wide leading-tight text-neutral-900">
                        Almost there!
                      </h1>

                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        Now that we know a little more about your project, one
                        of our ADU experts will be in touch for a free
                        consultation.
                      </p>
                    </div>
                    <div className="grid max-w-2xl grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          First name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            autoFocus="autofocus"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                            {...register("first_name")}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Last name
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                            {...register("last_name")}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                            {...register("email")}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="phone-number"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone Number
                        </label>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center">
                            <label htmlFor="country" className="sr-only">
                              Country
                            </label>
                            <select
                              id="country"
                              name="country"
                              autoComplete="country"
                              className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm"
                            >
                              <option>US</option>
                              <option>CA</option>
                              <option>EU</option>
                            </select>
                          </div>
                          <input
                            type="text"
                            name="phone-number"
                            id="phone-number"
                            className="block w-full rounded-md border-0 py-1.5 pl-16 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                            placeholder="+1 (555) 987-6543"
                            {...register("phone_number", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-9 flex justify-center sm:flex-row">
                      <Button
                        type={"submit"}
                        variant={"primary"}
                        size={"large"}
                        onClick={handleSubmit(onSubmit)}
                        disabled={!isValid || !isDirty}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footerComponent={<></>}
      />
    </>
  );
};
