import React from "react";
import { FormLayout, HeaderText, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePreQual } from "../../features/prequal/prequalSlice";

export const PreQualStep4 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { formData: leadData } = useSelector((state) => state.lead);
  const { recordId, formData } = useSelector((state) => state.prequal);

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({});

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: leadData.email,
      combined_income: Number(data.combined_income),
    };

    dispatch(updatePreQual(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"What is your household's annual income before taxes?"} />
          </>
        }
        mainComponent={
          <>
            <div className="flex max-w-lg justify-center pt-8 p-4 mx-auto">
              <div className="w-full">
                <label htmlFor="combined_income" className="sr-only">
                  Budget
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3.5">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    name=""
                    id="combined_income"
                    defaultValue={formData.combined_income}
                    className="block w-full rounded-lg border-0 py-3 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-red-400 sm:text-lg sm:leading-6 bg-gray-50"
                    autoFocus="autofocus"
                    placeholder="225,000"
                    {...register("combined_income", { required: true })}
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3.5">
                    <span
                      className="text-gray-500 sm:text-sm"
                      id="price-currency"
                    >
                      USD
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
