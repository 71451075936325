import React from "react";

export const ContainerLayout = ({ children }) => {
  return (
    <>
      <div className="overflow-visible sm:mx-auto sm:w-full sm:max-w-6xl">
        {children}
      </div>
    </>
  );
};
