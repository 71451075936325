import React from "react";
import { FormLayout, HeaderText, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";

export const Step9 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.lead);

  // Create a copy of formData and add plan_to_pay_ variables
  var localFormData = { ...formData };

  if ( formData.plan_to_pay !== undefined ) {
    localFormData.plan_to_pay_cash_savings = formData.plan_to_pay.includes("Cash Savings");
    localFormData.plan_to_pay_already_prequalified = formData.plan_to_pay.includes("I've already prequalified for a loan");
    localFormData.plan_to_pay_need_revival_help = formData.plan_to_pay.includes("I need Revival Homes' help to get financing");
  }

  const {
    handleSubmit,
    register,
  } = useForm({ defaultValues: localFormData });

  const onSubmit = (data) => {
    var plan_to_pay = [];
    if (data.plan_to_pay_cash_savings) {
      plan_to_pay.push("Cash Savings");
    }
    if (data.plan_to_pay_already_prequalified) {
      plan_to_pay.push("I've already prequalified for a loan");
    }
    if (data.plan_to_pay_need_revival_help) {
      plan_to_pay.push("I need Revival Homes' help to get financing");
    }
    if (data.plan_to_pay_dont_know) {
      plan_to_pay.push("I don't know");
    }

    const request = {
      id: recordId,
      email: formData.email,
      plan_to_pay: plan_to_pay,
    };

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"How do you plan to pay for the ADU project? Select all that apply"} />
          </>
        }
        mainComponent={
          <>
            <div className="flex justify-center">
            <div className="grid grid-cols-8 w-auto">
              <div className="block text-xl font-medium text-gray-900 col-span-7 mr-4">Cash Savings</div>
              <div>
                <input
                  type="checkbox"
                  name="plan_to_pay_cash_savings"
                  class="form-checkbox rounded h-6 w-6"
                  {...register("plan_to_pay_cash_savings")}
                />
              </div>
              <div className="block text-xl font-medium text-gray-900 col-span-7 mr-4">I've already prequalified for a loan</div>
              <div>
                <input
                  type="checkbox"
                  name="plan_to_pay_already_prequalified"
                  class="form-checkbox rounded h-6 w-6"
                  {...register("plan_to_pay_already_prequalified")}
                />
              </div>
              <div className="block text-xl font-medium text-gray-900 col-span-7 mr-4">I need Revival Homes' help to get financing</div>
              <div>
                <input
                  type="checkbox"
                  name="plan_to_pay_need_revival_help"
                  class="form-checkbox rounded h-6 w-6"
                  {...register("plan_to_pay_need_revival_help")}
                />
              </div>
              <div className="block text-xl font-medium text-gray-900 col-span-7 mr-4">I don't know</div>
              <div>
                <input
                  type="checkbox"
                  name="plan_to_pay_need_dont_know"
                  class="form-checkbox rounded h-6 w-6"
                  {...register("plan_to_pay_dont_know")}
                />
              </div>
            </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
