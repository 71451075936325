import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const PlacesInput = () => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE,
    });
  const [value, setValue] = useState("");

  return (
    <>
      <div className="overflow-visible">
        <Autocomplete
          apiKey={"AIzaSyCP34j4T0QCepn5I9afDiTsem-dyhcT0dU"}
          onPlaceSelected={(place) => {
          }}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          options={{
            componentRestrictions: { country: "us" },
          }}
        />
      </div>
      <div style={{ width: "250px" }}>
        <span>Debounced</span>
        <input
          style={{ color: "black" }}
          value={value}
          placeholder="Debounce 500 ms"
          onChange={(evt) => {
            getPlacePredictions({ input: evt.target.value });
            setValue(evt.target.value);
          }}
          loading={isPlacePredictionsLoading}
        />
        <div
          style={{
            marginTop: "20px",
            width: "200px",
            height: "200px",
            display: "flex",
            flex: "1",
            flexDirection: "column",
            marginBottom: "100px",
          }}
        >
          {!isPlacePredictionsLoading && (
            // <List
            //   dataSource={placePredictions}
            //   renderItem={(item) => (
            //     <List.Item onClick={() => setValue(item.description)}>
            //       <List.Item.Meta title={item.description} />
            //     </List.Item>
            //   )}
            // />
            <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {placePredictions.map((item) => (
                <li
                  key={item.description}
                  onClick={() => setValue(item.description)}
                >
                  {item.description}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
