// Slices for Initial State, Actions/Functions
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import leadService from "./leadService";

// Create Initial State for Users
const initialState = {
  leads: [],
  recordId: "",
  formData: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get Leads from Intake
export const getLeads = createAsyncThunk("leads/get", async (_, thunkAPI) => {
  try {
    return await leadService.getLeads();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

export const createLead = createAsyncThunk(
  "lead/create",
  async (request, thunkAPI) => {
    try {
      return await leadService.createLead(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateLead = createAsyncThunk(
  "lead/update",
  async (request, thunkAPI) => {
    try {
      return await leadService.updateLead(request);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Slice is collection of Reducers for Redux
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Ability to dispatch Reset function after Register or Login
    reset: (state) => {
      state.leads = [];
      state.formData = {};
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Leads
      .addCase(getLeads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLeads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.leads = action.payload;
      })
      .addCase(getLeads.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Create Lead
      .addCase(createLead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recordId = action.payload[0].id;
        state.formData = action.payload[0].fields;
      })
      .addCase(createLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Update Lead
      .addCase(updateLead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formData = action.payload;
      })
      .addCase(updateLead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
