import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";

export const Completed = ({ page, setPage }) => {
  const { formData } = useSelector((state) => state.lead);
  const navigate = useNavigate();

  var continueToPreQual = false;

  if ( formData.plan_to_pay !== undefined ) {
    if ( formData.plan_to_pay.includes("I need Revival Homes' help to get financing")
         || formData.plan_to_pay.includes("I don't know") )
    {
      continueToPreQual = true;
    }
  }

  return (
    <>
      <div className="overflow-hidden bg-transparent py-8 sm:py-12">
        <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
            {/* Left Side */}
            <div className="hidden lg:flex sm:px-6 lg:px-0">
              <div className="relative isolate overflow-hidden bg-red-300 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pt-16 sm:pl-16 sm:pr-0 lg:mx-0 lg:max-w-none">
                <div
                  className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-red-100 opacity-20 ring-1 ring-inset ring-white"
                  aria-hidden="true"
                />
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://uploads-ssl.webflow.com/63b71a6727a161f30cbd6e3e/63c0947e340013320cc18297_AdobeStock_558273730_Optimized.jpg"
                    alt="Product screenshot"
                    width={2432}
                    height={1442}
                    className="-mb-12 w-[57rem] max-w-none rounded-tl-xl"
                  />
                </div>
                <div
                  className="pointer-events-none absolute inset-0 sm:rounded-3xl"
                  aria-hidden="true"
                />
              </div>
            </div>

            {/* Right Side */}
            <div className="px-6 md:px-0 lg:pt-4 lg:pl-4 my-6">
              <div className="mb-8">
                <h1 className="text-left text-5xl font-bold tracking-wide leading-tight text-neutral-900">
                  { continueToPreQual ? "Thank you for your responses!" : "Talk to you soon! I did it." }
                </h1>
              </div>

              <div className="mb-8">
                  {continueToPreQual ? (
                    <>
                    <p className="mt-1 text-sm leading-6 text-gray-600 mb-4 ml-1">
                    You mentioned that you’re looking for some help financing your ADU. If you answer a few short questions, our ADU experts can determine what loan products may be the best fit for your project.
                    </p>
                      <Button
                        type={"submit"}
                        variant={"primary"}
                        size={"large"}
                        onClick={() => navigate("/pq")}
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                    Thank you for your submission. One of our ADU experts will be in touch shortly.
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
