import React from "react";
import { FormLayout, HeaderText, Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updateLead } from "../../features/lead/leadSlice";

export const Step8 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { recordId, formData } = useSelector((state) => state.lead);

  const {
    handleSubmit,
    register,
  } = useForm({ defaultValues: formData });

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: formData.email,
      needs_architect: data.needs_architect,
      needs_contractor: data.needs_contractor,
    };

    dispatch(updateLead(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"What can we help you with?"} />
          </>
        }
        mainComponent={
          <>
            <div className="flex justify-center">
            <div className="grid grid-cols-2 w-96">
              <div className="block text-xl font-medium text-gray-900">Design/Architect</div>
              <div>
                <input
                  type="checkbox"
                  name="needs_architect"
                  class="form-checkbox rounded h-6 w-6"
                  {...register("needs_architect")}
                />
              </div>
              <div className="block text-xl font-medium text-gray-900">Contractor</div>
              <div>
                <input
                  type="checkbox"
                  name="needs_contractor"
                  class="form-checkbox rounded h-6 w-6"
                  {...register("needs_contractor")}
                />
              </div>
            </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
