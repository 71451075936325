import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { FormLayout, Button, HeaderText } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePreQual } from "../../features/prequal/prequalSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const options = [
  {
    id: 1,
    title: "780-800+",
    description: "780-800+",
    value: "780-800+",
  },
  {
    id: 2,
    title: "760-779",
    description: "760-779",
    value: "760-779",
  },
  {
    id: 3,
    title: "740-759",
    description: "740-759",
    value: "740-759",
  },
  {
    id: 4,
    title: "720-739",
    description: "720-739",
    value: "720-739",
  },
  {
    id: 5,
    title: "700-719",
    description: "700-719",
    value: "700-719",
  },
  {
    id: 6,
    title: "690-699",
    description: "690-699",
    value: "690-699",
  },
  {
    id: 7,
    title: "680-689",
    description: "680-689",
    value: "680-689",
  },
  {
    id: 8,
    title: "670-679",
    description: "670-679",
    value: "670-679",
  },
  {
    id: 9,
    title: "660-669",
    description: "660-669",
    value: "660-669",
  },
  {
    id: 10,
    title: "640-659",
    description: "640-659",
    value: "640-659",
  },
];

export const PreQualStep5 = ({ page, setPage }) => {
  const dispatch = useDispatch();

  const { formData: leadData } = useSelector((state) => state.lead);
  const { recordId, formData } = useSelector((state) => state.prequal);
  const [selected, setSelected] = useState(options.find((obj) => obj.value === formData.estimated_credit_score));

  if ( selected === undefined ) {
    setSelected(false);
  }

  const {
    handleSubmit,
  } = useForm({});

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: leadData.email,
      estimated_credit_score: selected.value,
    };

    dispatch(updatePreQual(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setPage(page + 1);
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <>
            <HeaderText text={"My estimated credit score is:"} />
          </>
        }
        mainComponent={
          <>
            <div className="flex py-4 max-w-2xl justify-center mx-auto">
              <div className="mt-4 w-full">
                <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only">
                    {" "}
                    Server size{" "}
                  </RadioGroup.Label>
                  <div className="space-y-3">
                    {options.map((option) => (
                      <RadioGroup.Option
                        key={option.id}
                        value={option}
                        className={({ checked, active }) =>
                          classNames(
                            checked ? "border-transparent" : "border-gray-300",
                            active ? "border-red-400 ring-2 ring-red-400" : "",
                            "relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm text-base focus:outline-none sm:flex sm:justify-between"
                          )
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <span className="flex items-center">
                              <span className="flex flex-col text-base">
                                <RadioGroup.Label
                                  as="span"
                                  className="font-medium text-gray-900"
                                >
                                  {option.title}
                                </RadioGroup.Label>
                              </span>
                            </span>
                            <span
                              className={classNames(
                                active ? "border" : "border-2",
                                checked
                                  ? "border-red-400"
                                  : "border-transparent",
                                "pointer-events-none absolute -inset-px rounded-lg"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </RadioGroup.Option>
                    ))}
                  </div>
                </RadioGroup>
              </div>
            </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-5 flex justify-center sm:mt-4 sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Next
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
